<template>
  <div>
    
    <div class=" d-flex justify-space-between">
      <div class="pb-1 font-weight-bold pt-2 primary--text text-h6">Logo principal</div>
      <v-btn :disabled="$route.name == 'NewLogo'" 
        color="white" depressed rounded class="mx-2 y-3" @click="$router.push({name: 'NewLogo', params: {kind: 'logo'}})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        Cadastrar logo
      </v-btn>

    </div>

    <v-data-table :headers="[{ text: 'Posição', value: 'kind', align: 'start'}, ...headers]" :items="general_images.filter(el => el.kind != 'country_flag')" sort-by="calories" :loading="loading"  hide-default-footer>
      <template v-slot:[`item.kind`]="{ item }">
        {{ item.kind == 'logo_left' ? 'Lado esquerdo' : 'Lado direito' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :disabled="$route.params.id == item.id" icon color="primary" rounded @click="$router.push({name: 'EditLogo', params: {id: item.id}})"><v-icon>mdi-eye</v-icon></v-btn>
        <v-btn icon color="error" rounded @click="deleteFile(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>    

    <v-divider class="my-3"></v-divider>

    <div class=" d-flex justify-space-between">

      <div class="pb-1 font-weight-bold pt-2 primary--text text-h6"> Logos ministérios</div>
      <v-btn :disabled="$route.name == 'NewLogo'" color="white" depressed rounded class="mx-2 y-3" @click="$router.push({name: 'NewLogo', params: {kind: 'country_flag'}})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        Cadastrar logo ministério
      </v-btn>
    </div>

    <v-data-table :headers="headers" :items="general_images.filter(el => el.kind == 'country_flag')" sort-by="calories" :loading="loading">
      <template v-slot:top>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :disabled="$route.params.id == item.id" icon color="primary" rounded @click="$router.push({name: 'EditLogo', params: {id: item.id}})"><v-icon>mdi-eye</v-icon></v-btn>
        <v-btn icon color="error" rounded @click="deleteFile(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>    

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'GeneralImagesList',
  data: () => ({
    headers: [
      { text: 'Nome', value: 'name', align: 'start'},
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    general_images: [],
    dialog: false,
    local_file_preview: null,
    attached_file_url: '',
    file: null,
    loading: false,
    
  }),
  methods: {
    getSettingList(){
      this.loading = true
      Api.GeneralImages.index()
        .then( r=>r.data)
        .then(d => this.general_images = d)
        .catch((err) => this.addNotification({type: 'error', msg: `${err}`})).finally(() => {
          this.loading = false
        });
    },
    deleteFile(id){
      
      let msg_title = 'Deseja realizar esta operação?'
      let text = 'Após sua confirmação o item será excluído.'
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: msg_title,
        text: text,
        icon: 'question',
      }).then((r) => { 
        if(r.isConfirmed){
          this.loading = true
          Api.GeneralImages.delete(id).then((r)=>{
          this.addNotification({type: 'success', msg: 'Arquivo excluído com sucesso.'})
          }).catch((err)=>{
            console.log(err)
            this.addNotification({type: 'error', msg: `Ops, ocorreu um erro...`})

          }).finally(()=>{
            this.loading = false
            this.getSettingList()
            if(this.$route.params.id == id)this.$router.push({name: 'CertificateConfig'})
          })
        }
      })

    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  mounted () {
    this.getSettingList()
  },
  watch: {
    '$route.params'(newValue, oldValue) {
      if(newValue.updated_file){
        this.getSettingList()
      }
    }
  },
}
</script>

<style scoped>
</style>